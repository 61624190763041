import React from 'react'
import SellerieMotoContenu from '../components/contenu/SellerieMotoContenu';

function SellerieMoto() {
    return (
        <>
            <SellerieMotoContenu />
        </>
    )
}

export default SellerieMoto