import React from 'react'
import SellerieAutoContenu from '../components/contenu/SellerieAutoContenu';

function SellerieAuto() {
    return (
        <>
        <SellerieAutoContenu/>
        </>
    )
}

export default SellerieAuto