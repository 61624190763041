import React from 'react'
import BanquettesContenu from '../components/contenu/BanquettesContenu'


function Banquettes() {
    return (
        <>
            <BanquettesContenu />
        </>
    )
}

export default Banquettes