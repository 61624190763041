import React from 'react'
import DecorationContenu from '../components/contenu/DecorationContenu';

function Decoration() {
    return (
        <>
            <DecorationContenu />
        </>
    )
}

export default Decoration


